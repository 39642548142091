import axios from 'axios'
import store from '@/store'
import router from '../router/index'
import i18n from '@/assets/language/i18n'
import Message from "element-ui/packages/message/src/main";

const instance = axios.create({
  baseURL: '/centre',
  timeout: 60000
})
const defaultOpt = { login: true }

function baseRequest(options) {
  const token = store.state.token || ''
  const headers = options.headers || {}
  headers['lang'] =  i18n.locale || 'zh_CN'
  if (token) {
    headers['X-Token'] = token
  }
  options.headers = headers
  return new Promise((resolve, reject) => {
    instance(options).then(res => {
      const data = res.data || {}
      if (res.status !== 200) {
        return reject({ message: '请求失败', res, data })
      }
      if ([410000, 410001, 410002, 40000, 300005].indexOf(data.status) !== -1) {
        // store.dispatch('user/resetToken').then(() => {
        //   location.reload()
        // })
        store.commit('setToken', '')
        router.push({
          path: '/login',
        })
        Message({
          type: "error",
          message: data.message
        })
      } else if (data.status === 200) {
        return resolve(data, res)
      } else {
        Message({
          type: "error",
          message: data.message
        })
        return reject({ message: data.message, res, data })
      }
    }).catch(error => {
      Message({
        type: "error",
        message: error.response.data.message
      })
      reject({ error })
    })
  })
}

/**
 * http 请求基础类
 * 参考文档 https://www.kancloud.cn/yunye/axios/234845
 *
 */
const request = ['post', 'put', 'patch', 'delete'].reduce((request, method) => {
  /**
   *
   * @param url string 接口地址
   * @param data object get参数
   * @param options object axios 配置项
   * @returns {AxiosPromise}
   */
  request[method] = (url, data = {}, options = {}) => {
    return baseRequest(
      Object.assign({ url, data, method }, defaultOpt, options)
    )
  }
  return request
}, {});

['get', 'head'].forEach(method => {
  /**
   *
   * @param url string 接口地址
   * @param params object get参数
   * @param options object axios 配置项
   * @returns {AxiosPromise}
   */
  request[method] = (url, params = {}, options = {}) => {
    return baseRequest(
      Object.assign({ url, params, method }, defaultOpt, options)
    )
  }
})

export default request
