import request from './request'

// 校验
export function checkInformation(data) {
  return request.post('/manager/check', data)
}

// 注册验证码
export function sendCode(data) {
  return request.post('/util/sendCode', data)
}

// 注册
export function verificationCode(data) {
  return request.post('/manager/register', data)
}

// 重置密码
export function resetPassword(data) {
  return request.post('/manager/updatePwd', data)
}

// 登入图形验证码
export function graphicCode() {
  return request.post('/util/captchaImage')
}

// 登入
export function merchantLogin(data) {
  return request.post('/manager/login', data)
}

// 登录进入的主页(记录)
export function pageRecord(data) {
  return request.get('/statement/home', data)
}

// 银行数据
export function bankArr(data) {
  return request.get('/centreBank/registerBankTreeDate', data)
}

// // 分类列表
export function businessClass(data) {
  return request.get('/category/categoryList', data)
}

// 商户申请提交-前台接口
export function applySettlement(isSubmit, reviewId, data) {
  return request.post(`/merchant/submitMerInfo?isSubmit=${isSubmit}&&reviewId=${reviewId}`, data)
}

// 店铺信息
export function storeInformation(value) {
  return request.post(`/merchant/detail/${value}`)
}

// 站点列表
export function siteList() {
  return request.get('/site')
}

// 未开通的站点
export function unopenedSiteList() {
  return request.get('/site/getNoOpenSite')
}

// 一键开店
export function oneClickShop(siteId) {
  return request.post(`/merchant/oneOpenShop?siteId=${siteId}`)
}

// 站点审核的详情
export function auditDetails(data) {
  return request.get('/statement/detail', data)
}

// 审核中撤回
export function reviewWithdrawal(id, site) {
  return request.post(`/statement/rollBack?id=${id}&&site=${site}`)
}

